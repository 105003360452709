import { SlIconButton as IconBtnType } from '@shoelace-style/shoelace';
import { SlCheckbox, SlIconButton } from '@shoelace-style/shoelace/dist/react';
import { memo, useState } from 'react';
import { deepCompare } from '../utils';
import SubRow from './SubRow';
import { ColumnData, TableProps } from './Table';
import TableCell from './TableCell';

interface TableBodyRowProps<T> {
  index: number;
  rowSelection: boolean;
  expandable: boolean;
  selected: boolean;
  onCheckboxToggle: (id: string | number) => void;
  columns: Array<ColumnData>;
  data: TableProps<T>['data'][0];
  subrowProps?: TableProps<T>['subrowProps'];
}

const uuid = Math.random().toString(16).slice(-8);

function TableBodyRow<T extends Record<string, any>>(props: TableBodyRowProps<T>) {
  const { rowSelection, selected, onCheckboxToggle, columns, data, index } = props;
  const { expandable, subrowProps } = props;
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = (e: React.MouseEvent<IconBtnType>) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  const shouldExpand = typeof subrowProps?.renderer === 'function' || data?.subRows?.length > 0;

  return (
    <>
      <tr>
        {rowSelection && (
          <td id={`select-box-${index}`}>
            <div id="select-all" className="selection-box head-col">
              <SlCheckbox
                checked={selected}
                onSlChange={() => {
                  onCheckboxToggle(data?.selectionId!);
                }}
              />
            </div>
          </td>
        )}

        {expandable && (
          <td className="expand-button-td">
            {shouldExpand && (
              <SlIconButton name={expanded ? 'chevron-down' : 'chevron-right'} onClick={toggleExpansion} />
            )}
          </td>
        )}

        {columns.map((val, i) =>
          val?.show === false ? null : (
            <TableCell key={`value-${val.field}-${uuid}`} data={data} index={i} cell={val} />
          ),
        )}
      </tr>

      {/* Add subrow under the current row */}
      {expandable && shouldExpand && expanded && (
        <SubRow {...subrowProps} colspan={Number(columns?.length) + 1} data={data?.subRows} />
      )}
    </>
  );
}

export default memo(TableBodyRow, deepCompare);
