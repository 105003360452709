import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SlRadioButton, SlRadioGroup } from '@shoelace-style/shoelace/dist/react';
import type { SlRadioButton as HtmlRadioButton } from '@shoelace-style/shoelace';

function getStatus(current: TimeFilterPeriod, desired: TimeFilterPeriod) {
  if (current === desired) {
    return 'selected';
  }

  return '';
}

interface TimeFilterButtonProps {
  selected: TimeFilterPeriod;
  type: TimeFilterPeriod;
}

const TimeFilterButton: React.FC<TimeFilterButtonProps> = ({ selected, type }) => {
  const [t] = useTranslation();
  return (
    <SlRadioButton pill size="small" value={type} className={getStatus(selected, type)}>
      {t(type)}
    </SlRadioButton>
  );
};

export type TimeFilterPeriod = 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';

export interface TimeFilterProps {
  hidden?: Array<TimeFilterPeriod>;
  selected?: TimeFilterPeriod;
  onChange?: (period: TimeFilterPeriod) => void;
  name?: string;
}

export const TimeFilter: React.FC<TimeFilterProps> = ({
  selected = 'day',
  onChange = () => {},
  name = 'timeFilter',
  hidden = ['quarter', 'year'],
}) => {
  const handleChange = (e: Event) => {
    const { value } = e.target as HtmlRadioButton;
    onChange(value as TimeFilterPeriod);
  };

  const periods = ['hour', 'day', 'week', 'month', 'quarter', 'year'] as TimeFilterPeriod[];

  const visibleChips: Array<TimeFilterPeriod> = periods.filter(
    (period) => !hidden.includes(period as TimeFilterPeriod),
  );

  return (
    <SlRadioGroup name={name} value={selected} onSlChange={handleChange} className="time-filter">
      {visibleChips.map((period, i) => (
        <TimeFilterButton key={`${period}_${i}`} type={period} selected={selected} />
      ))}
    </SlRadioGroup>
  );
};
