import * as React from 'react';

import loadingLogo from './logo_white.svg';

export const LoadingIndicator: React.FC = () => {
  React.useEffect(() => {
    const app = document.querySelector('#app');

    if (app instanceof HTMLElement) {
      app.style.pointerEvents = 'none';
      app.style.touchAction = 'none';

      return () => {
        app.style.pointerEvents = '';
        app.style.touchAction = '';
      };
    }

    return () => {};
  }, []);

  return (
    <div className="backdrop-loader" onClick={(e) => e.stopPropagation()}>
      <div className="loading-logo">
        <img src={loadingLogo} alt="Loading..." width="100%" />
      </div>
    </div>
  );
};
