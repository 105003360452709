import climateZone from './climate-zone.svg';
import cloudPeers from './cloud-peers.svg';
import co2 from './co2.svg';
import iotCloud from './iot-cloud.svg';
import peersNetwork from './peers-network.svg';
import site from './site.svg';
import supplyLocation from './supply-location.svg';

const icons = {
  'climate-zone': climateZone,
  'cloud-peers': cloudPeers,
  co2: co2,
  'iot-cloud': iotCloud,
  'peers-network': peersNetwork,
  site: site,
  'supply-location': supplyLocation,
};

export default icons;
